import { keycloakDev } from './keycloak.env';
import { Organization } from '../app/constants';

const BACKEND_BASE_URL = 'https://api.portal2.dev.stmtires.com';
export const environment = {
  production: false,
  keycloak: keycloakDev,
  backendBaseUrl: BACKEND_BASE_URL,
  apiURL: `${BACKEND_BASE_URL}/api/v1`,
  inventoryManagerClientURL: 'https://inventory-manager.dev.stmtires.com',
  inventoryManagerAPIURL: 'https://api.inventory-manager.dev.stmtires.com',
  legacyPortalBaseUrl: 'https://portal.dev.stmtires.com',
  logo: 'assets/img/STMLogo_HOR.svg',
  organization: 'Southern Tire Mart' as Organization,
  // companyNumberAccessList: ['213', '743'], // not used yet
  thisCompanyNumber: ['213'],
};
