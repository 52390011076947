<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<nav class="navbar navbar-expand-lg navbar-fixed-top portal-navbar" *ngIf="layout">
  <div style="display:flex; width:100%">
    <!-- Topbar -->
    <div class="row" style="width:100%;padding:7px;">
      <!-- <div class="sidebar-toggle-button col-lg-0 col-md-1 col-xs-1">
        <p-button icon="fas fa-bars" (click)="toggleSidebar()"></p-button>
      </div> -->


      <div class="col-lg-2 col-md-2 col-xs-6 header-logo" style="padding-left: 0px !important; display: flex;">
        <div class="sidebar-toggle-button">
          <p-button icon="fas fa-bars" (click)="toggleSidebar()"></p-button>
        </div>
        <a routerLink="home">
          <img src="{{logo}}" alt="STM Logo" class="banner-logo" height="45">
        </a>
      </div>
      <div class="col-lg-3 col-md-3 top-links">
        <ul class="navbar-nav" *ngIf="layout.topbar.links.length > 0" role="navigation">
          <li class="nav-item" *ngFor="let link of layout.topbar.links">
            <a class="nav-link" [ngClass]="link.class" (click)="navigate(link)">{{link.title}}</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-7 col-md-7 col-xs-3 actions">
        <div class="org-switch-container" *ngIf="canSwitchOrganizations && selectedOrganization">
          <div [ngClass]="{ 'org-switch org-switch-left': 1, 'org-switch-active': selectedOrganization === 'Southern Tire Mart' }" (click)="selectOrganization('Southern Tire Mart')">STM</div>
          <div [ngClass]="{ 'org-switch org-switch-right': 1, 'org-switch-active': selectedOrganization === 'STM@Pilot' }" (click)="selectOrganization('STM@Pilot')">STM@Pilot</div>
        </div>
        <ul class="nav navbar-nav navbar-right user-menu float-right" (click)="userMenu.toggle($event)">
          <li class="dropdown">
            <a role="button" class=" user-header-dropdown-toggle">
              <span *ngIf="loggedInUser" class="desktop-only">{{loggedInUser.displayName}}</span>
              <img *ngIf="loggedInUser && loggedInUser.profileImageURL" [src]="loggedInUser.profileImageURL" class="header-profile-image" />
              <img *ngIf="loggedInUser && !loggedInUser.profileImageURL" src="assets/img/profile_default.svg"
                class="header-profile-image" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <p-menu #userMenu [popup]="true" [model]="userMenuItems"></p-menu>

  <!-- Sidebar navigation -->
  <div class="side-nav" [ngClass]="{'collapsed': collapsed}">
    <div class="container-fluid">
      <div role="navigation">
        <ul class="nav navbar-nav" *ngIf="layout.sidebar.links.length > 0">
          <!-- Side navigation links -->
          <li class="side-link" (click)="navigate(link)" style="cursor: pointer" *ngFor="let link of layout.sidebar.links">
            <a>{{link.title}}</a>
            <span *ngIf="link.title == 'Inventory'" [ngClass]="{'badge':1, 'rounded-pill':1, 'fa-stack':1,'pulsate': slowMovingBadgeValue > 0}">{{slowMovingBadgeValue}}</span>
          </li>
        </ul>
        <div class="sidenav-bg rgba-blue-strong"></div>
      </div>
    </div>
  </div>
  <!--/End Sidebar navigation -->

</nav>
