<div class="p-grid p-nogutter" style="display:flex; justify-content: center;">
  <h2 class="p-col-12" style="text-align: center; margin-top:2em">{{link?.title}}</h2>

  <div *ngIf="link?.links?.length > 0" class="p-col-10 p-grid" style="margin-top:3em; text-align:center;">
    <div *ngFor="let sublink of link.links" class="home-links p-lg-6">
      <div class="card" (click)="navigate(sublink.path, sublink.external)" style="cursor:pointer">
        <div class="p-grid">
          <div class="p-sm-6">
            <img width="100px" *ngIf="sublink.image" [src]="sublink.image" />
            <i *ngIf="sublink.icon" [ngClass]="sublink.icon"></i>
          </div>
          <div class="p-sm-6">
            <div class="card-block px-2">
              <h3 class="card-title">{{sublink.title}}</h3>
              <hr>
              <div class="card-text hidden-xs-down">{{sublink.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
