<div class="wrapper">
  <app-layout></app-layout>
  <div class="main">
    <router-outlet></router-outlet>
  </div>
</div>

<p-dialog [header]="dialog.header" modal="true" [(visible)]="dialog.visible">
  {{dialog.body}}
  <p-footer>
      <button type="button" pButton [icon]="dialog.buttonIcon || 'pi pi-check'" (click)="dialog.func()" [label]="dialog.buttonText || 'Go'"></button>
  </p-footer>
</p-dialog>