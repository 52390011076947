import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Layout, Link } from '../layout.interface';
import { LayoutService } from '../layout.service';
import { MenuItem } from 'primeng/api';
import { KeycloakService } from 'keycloak-angular';
import { GoogleAnalyticsService } from 'src/app/core/google-analytics.service';
import { Store } from 'src/app/stores/stores.interface';
import { StoreService } from 'src/app/stores/stores.service';
import { UsersService } from 'src/app/core/users.service';
import { Person } from 'src/app/people/person.interface';
import { environment } from '../../../environments/environment';
import { Organization } from 'src/app/constants';
// import { SlowMovingSocketMessage } from 'src/app/slow-moving/slow-moving.interface';
// import { SocketService } from 'src/app/shared/socket.service';
import { SlowMovingService } from 'src/app/slow-moving/slow-moving.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  gaEventCategory = 'Layout';
  layout: Layout;
  collapsed: boolean = true;
  loggedInUser: Person;
  selectedStore: Store;
  canSwitchOrganizations = false;
  logo = environment.logo;
  selectedOrganization: Organization;
  slowMovingBadgeValue: number = 0;
  userMenuItems: MenuItem[] = [
    {
      label: '',
      items: [
        {
          label: 'Signout',
          icon: 'pi pi-fw pi-sign-out',
          command: () => {
            this.googleAnalyticsService.eventEmitter(this.gaEventCategory, 'Clicked Signout');
            this.keycloakService.logout();
          },
        },
      ],
    }];

  // Get the topbar menu
  constructor(
    userService: UsersService,
    private layoutService: LayoutService,
    private keycloakService: KeycloakService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private storeService: StoreService,
    // private socketService: SocketService,
    // private slowMovingService: SlowMovingService,
  ) {
    userService.loggedInUser$.subscribe((person: Person) => {
      if (person) {
        this.selectedOrganization = environment.organization;
        this.loggedInUser = person;
        this.userMenuItems[0].label = person.displayName;
        this.selectedStore = this.loggedInUser.selectedStore;
        const orgs = this.loggedInUser.allowedOrganizations;
        this.canSwitchOrganizations = orgs && orgs.includes('Southern Tire Mart') && orgs.includes('STM@Pilot');
        // this.setupSlowMovingSocket(); // TODO is this what causes te keycloak error?
      }
    });

    storeService.selectedStore.subscribe((store: Store) => {
      this.selectedStore = store;
    });

    layoutService.layout.subscribe((layout: Layout) => {
      this.layout = layout;
    });
  }

  ngOnInit(): void {
    const sidebar = this.layout.sidebar;

    if (sidebar.links.length > 0) {
      this.activateLink(this.layout.sidebar.links[0]);
    }
  }

  setupSlowMovingSocket() { // TODO is this what causes te keycloak error?
    // this.socketService.initSocket(`${environment.inventoryManagerAPIURL}/slowmoving`);
    // this.socketService.socket.on('slowmoving', (countInfo: SlowMovingSocketMessage) => {
    //   if (parseInt(countInfo.storeNumber, 10) === this.loggedInUser.selectedStore.storeNumber) {
    //     this.slowMovingBadgeValue = countInfo.fromMePending + countInfo.fromMeAccepted;
    //   }
    // });
    // this.slowMovingService.triggerSlowMovingRequestSocket(this.loggedInUser.selectedStore.storeNumber, this.selectedStore.companyNumber);
  }

  navigate(link: Link): void {
    if (link.external) {
      window.location.href = link.path;
      this.googleAnalyticsService.eventEmitter(this.gaEventCategory, 'External Redirect', link.path);
    } else {
      this.router.navigate([link.path]);
    }

    this.activateLink(link);
  }

  /* Old Layout functions */

  toggleSidebar(): void {
    this.collapsed = !this.collapsed;
  }

  isCollapsed(): boolean {
    return this.collapsed;
  }

  activateLink(link: Link): void {
    this.layoutService.setActiveLink(link);
  }

  setSelectedStore(event: any): void {
    // TODO: Update selected store on logged in user record
    this.storeService.setSelectedStore(null, event.value);
  }

  selectOrganization(org: Organization) {
    switch (org) {
      case 'STM@Pilot':
        window.location.href = 'https://portal.stmpilot.com';
        break;
      case 'Southern Tire Mart':
        window.location.href = window.location.href.replace('stmpilot.com', 'stmtires.com');
        break;
    }
  }
}
