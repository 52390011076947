import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SlowMovingService {

  private apiURL = `${environment.apiURL}/slowmoving`;

  constructor(private http: HttpClient) { }

  async triggerSlowMovingRequestSocket(storeNumber: number, companyNumber: string) {
    const body = {
      companyNumber,
      storeNumber,
    };
    return this.http.post<any>(`${this.apiURL}/count`, body)
      .toPromise()
      .then((data) => { return data; });
    // .pipe((data) => { return data; });
  }
}
