import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Person } from './person.interface';
import { Observable, of } from 'rxjs';
import { BaseHttpService } from '../shared/base-http.service';
import { mergeMap } from 'rxjs/operators';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

@Injectable({ providedIn: 'root' })
export class PeopleService extends BaseHttpService {
  protected urlPath: string = 'people';
  constructor(protected http: HttpClient) {
    super();
  }

  getByEmployeeNumber(employeeNumber: number): Observable<Person> {
    const params = { employeeNumber };
    return this.http.get<Person>(`${this.url}employees`, { params: this.getHttpParams(params) }).pipe(
      mergeMap((person: Person) => this.addDisplayName(person)),
    );
  }

  getManagersByStoreNumber(storeNumber: number): Observable<Person[]> {
    const params = { storeNumber };
    return this.http.get<any[]>(`${this.url}managers`, { params: this.getHttpParams(params) });
  }

  getManagers(): Observable<Person[]> {
    return this.http.get<any[]>(`${this.url}managers`);
  }

  addDisplayName(person: Person): Observable<Person> {
    let displayName;
    const { maddenCoSalesperson, greatPlainsEmployee } = person.contexts;
    if (greatPlainsEmployee && greatPlainsEmployee.lastName) {
      if (greatPlainsEmployee.preferredName) {
        displayName = `${greatPlainsEmployee.preferredName} ${greatPlainsEmployee.lastName}`;
      } else {
        displayName = `${greatPlainsEmployee.firstName} ${greatPlainsEmployee.lastName}`;
      }
    } else {
      displayName = maddenCoSalesperson.name;
    }
    displayName = startCase(toLower(displayName)); // JIMMY ARROYO -> Jimmy Arroyo

    person.displayName = displayName;
    return of(person);
  }

}
