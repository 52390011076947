import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, QueryParams } from '../shared/base-http.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Store } from './stores.interface';
import { PagedResponse } from '../shared/crud-base.service';
import { take, map, skip } from 'rxjs/operators';
import * as moment from 'moment';
import { UsersService } from '../core/users.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class StoreService extends BaseHttpService {
  protected urlPath: string = 'stores';
  private selectedStoreBehaviorSubject: BehaviorSubject<Store> = new BehaviorSubject<Store>(null);
  selectedStore: Observable<Store> = this.selectedStoreBehaviorSubject.asObservable();
  private previousSelectedStore: Store;
  // v2 MODS
  protected backendBaseURL: string = environment.backendBaseUrl;
  private v2URL: string = `${this.backendBaseURL}/api/v2/stores`;

  constructor(
    protected http: HttpClient,
    protected cookieService: CookieService,
    protected usersService: UsersService,
  ) {
    super();
  }

  findMany(params?: QueryParams): Observable<PagedResponse<Store>> {
    params.companyNumber = environment.thisCompanyNumber;
    return this.http.get<PagedResponse<Store>>(this.v2URL, { params: this.getHttpParams(params) });
  }

  getStoreByNumber(storeNumber: string | number): Promise<Store> {

    //console.log('getstorebynumber:', storeNumber);

    return this.findMany({ storeNumber }).pipe(
      take(1),
      map((res: PagedResponse<Store>) => res.documents[0]),
    ).toPromise();
  }

  async setSelectedStore(storeNumber: string | number, store?: Store): Promise<Store> {
    //console.log('setselstore:', storeNumber);
    const newStore = store ? store : await this.getStoreByNumber(storeNumber);
    if (newStore && newStore.storeNumber) {
      this.setStoreNumberCookie(newStore.storeNumber);
    }
    this.previousSelectedStore = this.selectedStoreBehaviorSubject.value;
    this.selectedStoreBehaviorSubject.next(newStore);
    // this.usersService.updateLoggedInUserSelectedStore(); // TODO did this break any behavior
    return newStore;
  }

  undoSelectedStoreChange(): void {
    this.selectedStoreBehaviorSubject.next(this.previousSelectedStore);
  }

  calculateDistanceBetweenTwoStores(store1: Store, store2: Store): number {
    if (!store1.location || !store2.location) {
      return null;
    }
    const lat1 = store1.location.lat;
    const lon1 = store1.location.lng;
    const lat2 = store2.location.lat;
    const lon2 = store2.location.lng;

    // https://www.geodatasource.com/developers/javascript
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }

  private setStoreNumberCookie(storeNumber) {

    //console.log('setStoreNumberCookie:', storeNumber);
    // set for 30 days
    const expires = moment().add(30, 'days').toDate();

    let host = location.hostname;
    if (host !== 'localhost') host = `.${host}`;

    this.cookieService.put('selectedStore', storeNumber, { expires, path: '/', domain: host, sameSite: 'lax' });
  }
}
