import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe, DatePipe } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { TimeagoModule } from 'ngx-timeago';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { FieldsetModule } from 'primeng/fieldset';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { GMapModule } from 'primeng/gmap';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToolbarModule } from 'primeng/toolbar';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SliderModule } from 'primeng/slider';
import { InputSwitchModule } from 'primeng/inputswitch';
// import { BadgeModule } from 'primeng/badge';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { SpreadsheetImportComponent } from './spreadsheet-import/spreadsheet-import.component';
import { SocketService } from './socket.service';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TimeagoModule.forRoot(),
    NgxMaskModule.forRoot(options),
    NgxSpinnerModule,
    /* PrimeNG Imports */
    MenubarModule,
    InputTextModule,
    ButtonModule,
    MenuModule,
    TableModule,
    PanelModule,
    TabViewModule,
    GMapModule,
    TabMenuModule,
    CheckboxModule,
    RadioButtonModule,
    AccordionModule,
    CardModule,
    FieldsetModule,
    BreadcrumbModule,
    DialogModule,
    MessageModule,
    SelectButtonModule,
    InputMaskModule,
    TooltipModule,
    AutoCompleteModule,
    ToastModule,
    MessagesModule,
    DropdownModule,
    EditorModule,
    ConfirmDialogModule,
    ToolbarModule,
    VirtualScrollerModule,
    InputNumberModule,
    SliderModule,
    InputSwitchModule,
    // BadgeModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    TimeagoModule,
    NgxMaskModule,
    NgxSpinnerModule,
    /* PrimeNG Exports */
    MenubarModule,
    InputTextModule,
    ButtonModule,
    MenuModule,
    TableModule,
    PanelModule,
    TabViewModule,
    GMapModule,
    TabMenuModule,
    CheckboxModule,
    RadioButtonModule,
    AccordionModule,
    CardModule,
    FieldsetModule,
    BreadcrumbModule,
    DialogModule,
    MessageModule,
    SelectButtonModule,
    InputMaskModule,
    TooltipModule,
    AutoCompleteModule,
    ToastModule,
    MessagesModule,
    DropdownModule,
    EditorModule,
    ConfirmDialogModule,
    ToolbarModule,
    VirtualScrollerModule,
    PageLoaderComponent,
    InputNumberModule,
    SpreadsheetImportComponent,
    SliderModule,
    InputSwitchModule,
    // BadgeModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    SocketService,
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
  ],
  declarations: [PageLoaderComponent, SpreadsheetImportComponent],
})
export class SharedModule { }
