import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from './core/auth.guard';
import { LinkListComponent } from './layout/link-list/link-list.component';

const routes: Routes = [
  //  {
  //    path: '',
  //    redirectTo: '/dashboard',
  //    pathMatch: 'full',
  //  },
  {
    path: '',
    canActivate: [CanAuthenticationGuard],
    component: LinkListComponent,
    children: [
      { path: 'dashboard', component: LinkListComponent },
      { path: 'commissions', component: LinkListComponent },
    ],
  },
  {
    path: 'dashboard',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule),
  },
  {
    path: 'commissions',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./commissions/commissions.module').then(m => m.CommissionsModule),
  },
  {
    path: 'marketing',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule),
  },
  {
    path: 'workorders',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./work-orders/work-orders.module').then(m => m.WorkOrdersModule),
  },
  {
    path: 'sandbox',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./sandbox/sandbox.module').then(m => m.SandboxModule),
  },
  {
    path: 'accounting',
    canActivate: [CanAuthenticationGuard],
    loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
