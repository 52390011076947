import { Component, OnInit } from '@angular/core';
import { Link } from '../layout.interface';
import { LayoutService } from '../layout.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
})
export class LinkListComponent implements OnInit {
  link: Link;

  constructor(layoutService: LayoutService, private router: Router) {
    layoutService.activeLink.subscribe((link: Link) => {
      this.link = link;
    });
  }

  ngOnInit(): void {}

  navigate(path: string, external: boolean): void {
    if (external) {
      window.open(path, '_blank');
    } else {
      this.router.navigate([path]);
    }
  }
}
