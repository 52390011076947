import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../environments/environment';

export enum Action {
  JOINED,
  LEFT,
  RENAME,
}

// Socket.io events
export enum Event {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
}

const SERVER_URL = `${environment.backendBaseUrl}/slowmoving`;
@Injectable({ providedIn: 'root' })
export class SocketService {
  socket: Socket;

  constructor(namespace?: string) {
    this.initSocket(`${SERVER_URL}${namespace}`);
  }

  initSocket(url: string = ''): void {
    this.socket = io(url);
    this.socket.on('connect', () => {
      console.log('SOCKET | CONNECTED');
    });

  }

  // constructor(socketURI?: string) {
  //   const url = (socketURI) ? socketURI : SERVER_URL;
  //   this.initSocket(url);
  // }

  // public initSocket(uri: string = ''): void {
  //   this.socket = socketIo(uri);
  //   this.socket.on('connect', () => {
  //     // console.log('SOCKET | CONNECTED');
  //   });
  //   this.socket.on('event', (data: any) => {
  //     console.log(`SOCKET | data -  ${data}`);
  //   });
  // }

  // public send(message): void {
  //   this.socket.emit('message', message);
  // }

  public onMessage(): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.socket.on('message', (data: any) => observer.next(data));
    });
  }

  // public onSlowMoving(): Observable<any> {
  //   return new Observable<any>((observer: Observer<any>) => {
  //     this.socket.on('slowmoving', (data: any) => observer.next(data));
  //   });
  // }

  public onEvent(event: string = 'message'): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.socket.on(event, (data: any) => observer.next(data));
    });
  }
}
