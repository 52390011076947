import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StoreService } from '../stores/stores.service';
import { Person } from '../people/person.interface';
import { map } from 'rxjs/operators';
import { PagedResponse } from '../shared/crud-base.service';
import { Store } from '../stores/stores.interface';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { environment } from 'src/environments/environment';
import { Organization } from './auth.interface';
import { uniq } from 'lodash';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private loggedInUserSubject: BehaviorSubject<Person> = new BehaviorSubject<Person>(null);
  loggedInUser$: Observable<Person> = this.loggedInUserSubject.asObservable();

  constructor(
    private storeService: StoreService,
    private cookieService: CookieService,
  ) { }

  get loggedInUser(): Person {
    return this.loggedInUserSubject.value;
  }

  async setLoggedInUser(person: Person): Promise<Person> {
    person.displayName = this.getDisplayName(person);
    person.employeeId = Number(person.contexts.keycloakProfile.attributes.employee_id);
    person.isExecutive = this.isExecutive(person);
    person.isManager = this.isManager(person);
    person.isSalesperson = this.isSalesperson(person);
    person.isTrainer = this.isTrainer(person);
    person.allowedOrganizations = this.getAllowedOrganizations(person);

    await this.setUserStores(person);
    this.updateLoggedInUserSelectedStore();
    this.loggedInUserSubject.next(person);
    (window as any).loggedInUser = person;
    return person;
  }

  async setUserStores(person: Person): Promise<Person> {
    const storeNumbers = person.contexts.keycloakProfile.attributes.store_numbers;
    //console.log('set userstores');

    try {
      person.stores = await this.storeService.findMany({ storeNumber__in: storeNumbers })
        .pipe(map((res: PagedResponse<Store>) => res.documents)).toPromise();
    } catch (error) {
      console.error('error returning stores:', error);
      person.stores = [];
    }

    person.selectedStore = person.stores.find((s: Store) => s.storeNumber === Number(storeNumbers[0]));
    const cookieStore = this.cookieService.get('selectedStore');
    if (cookieStore && person.stores.map(s => s.storeNumber.toString()).includes(cookieStore)) {
      this.storeService.setSelectedStore(cookieStore, null);
    } else {
      this.storeService.setSelectedStore(null, person.selectedStore);
    }
    return person;
  }

  updateLoggedInUserSelectedStore(): void {
    this.storeService.selectedStore.subscribe((store: Store) => {
      const user = this.loggedInUser;
      if (store && user) {
        user.selectedStore = store;
        this.loggedInUserSubject.next(user);
      }
    });
  }

  getDisplayName(person: Person): string {
    let displayName;
    const { keycloakProfile, greatPlainsEmployee } = person.contexts;
    if (greatPlainsEmployee && greatPlainsEmployee.lastName) {
      if (greatPlainsEmployee.preferredName) {
        displayName = `${greatPlainsEmployee.preferredName} ${greatPlainsEmployee.lastName}`;
      } else {
        displayName = `${greatPlainsEmployee.firstName} ${greatPlainsEmployee.lastName}`;
      }
    } else {
      displayName = keycloakProfile.displayName;
    }

    displayName = startCase(toLower(displayName)); // JIMMY ARROYO -> Jimmy Arroyo
    if (!environment.production) {
      displayName = `DEV ${displayName}`;
    }
    return displayName;
  }

  isExecutive(person: Person): boolean {
    return person.contexts.keycloakProfile.attributes.roles.includes('executive');
  }

  isManager(person: Person): boolean {
    return person.managedStoreNumbers && person.managedStoreNumbers.length > 0;
  }

  isSalesperson(person: Person): boolean {
    return !this.isManager(person)
      && !this.isExecutive(person)
      && Boolean(person.contexts.maddenCoSalesperson);
  }

  isTrainer(person: Person): boolean {
    return person.contexts.greatPlainsEmployee
      && person.contexts.greatPlainsEmployee.divisionKey === 'CORTRA';
  }

  getAllowedOrganizations(person: Person): Organization[] {
    let homeOrganizations: Organization[] = person.contexts.keycloakProfile.attributes.organization;
    homeOrganizations = Array.isArray(homeOrganizations) ? homeOrganizations : [homeOrganizations];
    return uniq(homeOrganizations.concat(person.contexts.keycloakProfile.attributes.allowedOrganizations || []));
  }
}
