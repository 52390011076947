import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Link, Layout, Dialog } from './layout.interface';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  defaultLayout: Layout = {
    topbar: { links: [] },
    sidebar: { links: [] },
    dialog: { visible: false },
  };
  private activeLinkBehaviorSubject: BehaviorSubject<Link> = new BehaviorSubject<Link>(null);
  private layoutBehaviorSubject: BehaviorSubject<Layout> = new BehaviorSubject<Layout>(this.defaultLayout);

  activeLink: Observable<Link> = this.activeLinkBehaviorSubject.asObservable();
  layout: Observable<Layout> = this.layoutBehaviorSubject.asObservable();

  constructor() {}

  setActiveLink(link: Link): Promise<void> {
    this.activeLinkBehaviorSubject.next(link);
    return Promise.resolve();
  }

  init(layout: Layout): Promise<void> {
    this.layoutBehaviorSubject.next(layout);
    return Promise.resolve();
  }

  updateDialog(dialog: Dialog): Promise<void> {
    const layout = this.layoutBehaviorSubject.getValue();
    layout.dialog = dialog;
    this.layoutBehaviorSubject.next(layout);
    return Promise.resolve();
  }
}
