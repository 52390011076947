export const keycloakDev = {
  url: 'https://id.stmtires.com/auth',
  realm: 'STM_Dev',
  clientId: 'STM_Portal_2.0',
};

export const keycloakProd = {
  url: 'https://id.stmtires.com/auth',
  realm: 'STM_Prod',
  clientId: 'STM_Portal_2.0',
};
