import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DoBootstrap } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { LayoutModule } from './layout/layout.module';
import { InventoryModule } from './inventory/inventory.module';
import { StoresModule } from './stores/stores.module';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CookieModule } from 'ngx-cookie';
import { BadgeModule } from 'primeng/badge';
import { SlowMovingService } from './slow-moving/slow-moving.service';

const keycloakService = new KeycloakService();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    LayoutModule,
    InventoryModule,
    StoresModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    CookieModule.forRoot(),
    BadgeModule,
  ],
  providers: [{
    provide: KeycloakService,
    useValue: keycloakService,
  },
    SlowMovingService,
  ],
  entryComponents: [AppComponent],
})
export class AppModule implements DoBootstrap {
  async ngDoBootstrap(app) {
    try {
      await keycloakService.init({
        config: environment.keycloak,
        enableBearerInterceptor: true,
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false,
        },
      });
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('App Module: Keycloak init failed', error);
    }
  }
}
